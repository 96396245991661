// Avatar

.avatar-component {
	input[type="file"] {
		display: none;
	}
	img {
		width: 150px;
		height: 150px;
		border-radius: 75px;
		object-fit: cover;
		cursor: pointer;
	}
	margin-top: 20px;
	margin-bottom: 10px;
}