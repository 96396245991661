// Add Files

.container-add-files {
	.formats {
		font-size: 11px;
	}
	.item-add-file {
		border: 1px solid $blue;
		border-radius: 3px;
		display: inline-block;
		padding: 5px;
		padding-left: 15px;
		padding-right: 15px;
		cursor: pointer;
		margin-right: 10px;
		margin-bottom: 20px;
		input[type="file"] {
			pointer-events: none;
			display: none;
		}
		button {
			background-color: transparent !important;
			padding: 0px !important;
			min-width: auto !important;
			padding-left: 5px !important;
			padding-right: 5px !important;
			i {
				color: $danger !important;
			}			
		}
		p {
			display: inline-block;
			margin-right: 5px;
			margin-bottom: -5px;
			font-size: 14px;
			font-family: $font-family-light;
			color: $blue;
			@include ellipsis();
			max-width: 200px;
			&.format {
				font-family: $font-family-bold;
				text-transform: uppercase;
			}
		}
		.fa {
			color: $blue;
			font-size: 16px;
		}
	}
}