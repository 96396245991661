// Pacientes

#patients {
	.ellipsis {
		max-width: 200px;
		@include ellipsis();
	}
}

.modal-change-password {
	.h2-title {
		font-family: $font-family-light;
		margin-bottom: 20px;
		font-size: 18px;
		color: $blue;
	}
}