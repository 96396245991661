.form-filter {
    margin-bottom: 1rem;
    label {
        color: $black;
        margin-bottom: .4rem;
    }
}

// label {
//     color: $yellow;
//     margin-bottom: .4rem;
// }
   
.react-datepicker__day--selected {
    background-color: $black;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected {
    background-color: $black;
}

