// Button

.btn-primary {
	background-color: $yellow !important;
    border-radius: $general-radius;
	color: #fff !important;
	min-width: 120px;
	outline: 0px !important;
	border: 0px !important;
	box-shadow: none !important;
	font-size: 15px;
	padding-top: 8px;
	padding-bottom: 8px;

	.badge {
		margin-left: 5px;
	}
}

.btn-yellow {
	background-color: $yellow !important;
}

.btn-aqua {
	background-color: $aqua !important;
}

.btn-blue {
	background-color: $blue !important;
}

.btn-block {
	width: 100%;
}

.btn-dark {
	background-color: $black !important;
}

.btn-gray-blue {
	background-color: $gray-blue !important;
}

.btn-outline {
	border: 1px solid $gray !important;
	background-color: transparent !important;
	min-width: auto !important;
	border-radius: 3px !important;
}

.btn-red {
	background-color: $danger !important;
}

.btn-green {
	background-color: $green !important;
}

.btn-orange {
	background-color: $orange !important;
}

.btn-small {
	padding: 5px !important;
	padding-left: 8px !important;
	padding-right: 8px !important;
	font-size: 12px !important;
	min-width: 30px !important;
}

.btn-align-bottom {
	@media (min-width: 768px) {
		margin-top: 32.5px;
	}	
	margin-bottom: 15px;
}

.btn-add {
	padding: 0;
	margin: 0;
	@media (max-width: 767px) {
		margin-top: -350%;
	}	
}

.text-black {
    color: $black !important;
}
.text-white {
    color: $white important;
}