// Variables
$green: #2f4649;
$green2: #2b3e40;
$green3: #2f4649;
$golden: #e0c37e;
$cream: #f7f7f7;
$gray-green: #8d989a;
$gray: #e8e8e8;
$blue: #007bff;
$blue2: #1a237e;
$blue3: #303f9f;
$gray-blue: #c5cae9;
$gray-blue2:#7986cb;
// $black: #222d32;
$white: #fff;
$input: /*#f1f1f1;*/ $gray-blue2;
$active: #0d1214;
$danger: #e53935;
$green1: #43A047;
$orange: #FB8C00;
$facebook: #3c5a9a;
$google: #de5145;
$epayco: #ff5700;
$aqua: #3c4586;
$mobile: #1e253f;

$black: #08090a;
$black-2: #101111;
$gray-dark: #282828;
$gray-light: #e2e2e2;
$yellow: #f7b81c;
$yellow-light: #f9c649;
$red: $google;

$width-menu: 260px;
$height-header: 100px;

$general-radius: 13px;
$table-radius: $general-radius;

$fonts-path: "../../assets/fonts/";

$font-family: "Montserrat";
$font-family-thin: "Montserrat-Thin";
$font-family-light: "Montserrat-Light";
$font-family-medium: "Montserrat-Medium";
$font-family-bold: "Montserrat-Bold";
$font-family-black: "Montserrat-Black";