// Medical History

.medical-history {
	margin-top: 1rem;
	.title-patient {
		font-family: $font-family-medium;
		color: $black;
		font-size: 20px;
		margin-bottom: 20px;
		span {
			font-family: $font-family-bold;
		}
	}
	.ellipsis {
		@include ellipsis();
		max-width: 300px;
	}
	.btn-back {
		.fa {
			margin-right: 5px;
		}
		margin-bottom: 20px;
	}
}