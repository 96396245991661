// Login
.login {
	display: flex;
	background-color: $green;
	align-items: center;
	min-height: 100vh;

	&-background {
		@media (max-width: 768px) {
			display: none;
		}	

		width: 55vw;
		background-image: url('../../assets/img/background-login.jpg');width: 68%;
		height: 100vh;
		background-size: cover;
		


		@media (max-width: 1280px) {
			padding-left: 3rem !important;
			padding-right: 3rem !important;
			width: 65vw;
		}
	}

	&-form {
		width: 45vw;
		padding-left: 8rem;
		padding-right: 8rem;

		@media (max-width: 2560px) {
			padding-left: 10rem;
			padding-right: 10rem;
		}

		@media (max-width: 1440px) {
			padding-left: 7rem !important;
			padding-right: 7rem !important;
		}

		@media (max-width: 1280px) {
			padding-left: 3rem !important;
			padding-right: 3rem !important;
			width: 35vw;
		}

		@media (max-width: 768px) {
			width: 100vw;
			padding-left: 4rem !important;
			padding-right: 4rem !important;
		}

		@media (max-width: 425px) {
			padding-left: 1rem !important;
			padding-right: 1rem !important;
		}


		&-logo {
			padding: 0 2rem;
			margin-bottom: 3rem;
		}

		&-btn {
			margin-left: 4.5rem;
			margin-right: 4.5rem;
			margin-top: 1.3rem;
			button {
				background-color: $yellow !important;
				border-radius: 6px;
			}
			.register-temp {
				background-color: $red !important;
			}

			@media (max-width: 1024px) {
				margin-left: 1rem;
				margin-right: 1rem;
			}

			@media (max-width: 768px) {
				margin-left: 3rem;
				margin-right: 3rem;
			}

			@media (max-width: 425px) {
				margin-left: 0;
				margin-right: 0 ;
			}
		}

		&-remember-link, &-register, &-register button {
			font-size: .95rem;
			margin: 0;
			padding: 0 .2rem;
			span {
				font-family: $font-family-light !important;
			}
		}

		&-remember {
			font-family: $font-family-light !important;
			&-line {
				background-color: white;
				height: 1px;
				flex: 1;
			}
		}

		&-remember {
			display: flex;
			align-items: center;
			margin-top: 2rem;
		}

		.form-group:nth-child(1) {
			margin-bottom: 1.6rem;
		}

		input.form-control {
			border-radius: 0 !important;
			background-color: $white !important;
			padding-bottom: 14px !important;
		}
	}

	&-social {
		display: flex;
		justify-content: space-evenly;
		margin-top: 2.5rem;
		button {
			font-family: $font-family-light !important;

			&.btn-facebook {
				background-color: $blue !important;
				flex: 1;
				margin-right: 5px;
				@media (max-width: 1024px){
				margin-right: 0;
				}

			}

			&.btn-google {
				background-color: $red !important;
				flex: 1;
				margin-left: 5px;
				@media (max-width: 1024px){
					margin-left: 0;
				}
			}
		}

		@media (max-width: 1024px){
			flex-direction: column;
			align-self: center;
		}

		@media (max-width: 768px){
			padding-left: 4.5rem;
			padding-right: 4.5rem;
		}

		@media (max-width: 425px) {
			padding-left: 2rem;
			padding-right: 2rem;
		}

		button {
			@media (max-width: 1024px) {
				margin-bottom: 1rem;
			}
			padding: .1rem .4rem;
			font-size: .9rem;
			border-radius: 4px;
			.fa {
				padding-right: 5px;
			}
		}
	}
}

#modal-password-recovery {
	max-width: 35% !important;
	@media (max-width: 1440px){
		max-width: 50% !important;
	}
	@media (max-width: 1024px){
		max-width: 70% !important;
	}
	@media (max-width: 768px){
		max-width: 100% !important;
	}
	.modal-header {
		background-color: $green2;
	}
	.modal-content {
		border-color: $blue3 !important;
		.login-recover-password-logo {
			padding: 0 2rem;
			margin-bottom: 1.5rem;
			img {
				width: 5vw;
				@media (max-width: 768px){
					width: 15vw;
				}
			}
		}
		.login-recover-password-title {
			padding: 0 2rem;
			margin-bottom: 1rem;
			h4 {
				color: $black
			}
		}
		.login-recover-password-text {
			padding: 0 2rem;
			margin-bottom: 2rem;
		}
		.login-recover-password-input {
			padding: 0 1rem;
			margin-bottom: 1.5rem;
		}
		.modal-body {
			background-color: $white;
			padding: 2.25rem;
			color: $black;
			@media (max-width: 768px){
				padding: 1rem;
			}

			button {
				width: 70%;
			}
			.back-button {
				background-color: $gray-blue !important;
				@media (max-width: 768px){
					margin-bottom: 1rem;
				}
			}
		}
	}
  }