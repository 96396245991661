// Select

select.form-control {
	border-radius: 13px !important;
	background-color: $cream !important;
	outline: 0px !important;
	box-shadow: none !important;
	border: 0px !important;
	font-size: 14px;
	height: 40px;
	color:black
	// &.white {
	// 	background-color: $white !important;
	// }
	&.input-gray{
		background-color: $cream !important;
	}
}