// Register
.register {
	display: flex;
	background-color: $green2;
	align-items: center;
	justify-content: center;
	min-height: 100vh;

	&-form {
		width: 45vw;
		padding-left: 6rem;
		padding-right: 6rem;
		padding-top: 2rem;
		padding-bottom: 2rem;

		@media (max-width: 2560px) {
			padding-left: 10rem;
			padding-right: 10rem;
			
		}

		@media (max-width: 1440px) {
			padding-left: 6rem !important;
			padding-right: 6rem !important;
		}

		@media (max-width: 1280px) {
			width: 35vw;
			padding-left: 2rem !important;
			padding-right: 2rem !important;
		}

		@media (max-width: 1024px) {
			padding-left: 2rem !important;
			padding-right: 2rem !important;
		}

		@media (max-width: 768px) {
			width: 100vw;
			height: auto;
			overflow-x: initial;
		}

		@media (max-width: 425px) {
			padding-left: 2rem !important;
			padding-right: 2rem !important;
		}
		
		input, select, textarea, .container-datepicker  {
			border-radius: 0 !important;
			background-color: $white !important;
		}

		.container-datepicker  {
			height: 35px;

			p {
				margin-top: 10px;
				margin-left: 16px;
				margin-bottom: 4px;
			}
		}

		input.form-control {
			background-color: $white !important;
			padding-bottom: 15px !important;
			height: calc(1.5em + 2px);
		}

		select {
			height: 35px !important;
			padding-top: .375rem !important;
			padding-bottom: 0 !important;
			padding-left: .55rem !important;
			padding-right: .75rem !important;
		}

		textarea {
			padding-top: 10px !important;
		}

		&-header {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
		}

		&-title {
			margin-top: .6rem;
			margin-bottom: .6rem;
			font-size: 1.1rem;
			font-family: $font-family-bold;
			color: $black;
			text-align: center;
		}	

		&-icon {
			display: flex;
			width: 80px;
			height: 80px;
			border-radius: 50%;
			background-color: $gray;
			border: 1px solid $black;
			background-size: contain;
			background-position: center;

			img {
				width: 80px;
				height: 80px;
				border-radius: 50%;
				object-fit: cover;
				border: 1px solid $black;
			}

			&--padding {
				padding: .9rem;
			}
		}

		&-buttons {
			padding: .3rem 2rem;
			display: flex;
			justify-content: space-evenly;

			@media (max-width: 1024px) {
				flex-direction: column-reverse;
				&-submit {
					margin-bottom: 1rem;
				}
			}

			button {
				min-width: 130px !important;
				padding-top: 5px !important;
				padding-bottom: 4px !important;
				border-radius: 5px !important;
			}
			&-back {
				background-color: $gray !important;
				color: $black !important;
			}
		}
	}
}

button.register-form-buttons-back {
	background-color: $gray-blue !important;
	color: $black !important;
}