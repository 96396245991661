// Input

textarea.form-control {
	border-radius: 3px;
	background-color: $cream !important;
	outline: 0px !important;
	box-shadow: none !important;
	border: 0px !important;
	font-size: 14px;
	padding-top: 20px !important;
	padding-bottom: 20px !important;
	resize: none;
	color:$black;
}