.header {
    height: 100px;
    display: flex;
    justify-content: space-between;
    background-color: $green2;

    &-start, &-end {
        height: 100%;
    }

    &-logo {
        height: 100%;
        width: $width-menu;
        background-color: $green3;
        padding-top: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    &-end {
        display: flex;
    }

    &-user {
        height: 100%;
       
        align-items: center;
        &-name {
            color: $white;
        }
        &-avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 1px solid $golden;
            background-image: url('../../assets/img/avatarDefault.png');
            background-size: cover;
            margin-left: 1.5rem;
            margin-right: 1rem;
            background-position: center;
        }
    }

    &-buttons {
        display: flex;
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 40px;

            img {
                width: 15px;
            }
        }

        &-logout {
            background-color: $gray-green;
        }

        &-profile {
            background-color: $gray-green;
        }
    }
}