// Title

.title-component {
	display: flex;
    align-items: center;
    justify-content: space-between;
	background-color: $green	2;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 2.2rem;
	padding-right: 1.6rem;
	font-size: 1rem;
	font-family: $font-family-bold;
	color: $white;
	margin: 0px !important;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
	position: relative;
}