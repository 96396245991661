// Table

.table-component {
	background-color: $white;
	border-bottom-right-radius: $table-radius;
	border-bottom-left-radius: $table-radius;
	margin: 0px;
	thead {
		border-bottom: 1px solid $gray;
		background-color: $golden;
		th {
			border: 0px !important;
			text-align: center;
			padding-bottom: .75rem;

			// &:last-child {
			// 	text-align: right;
			// 	padding-right: 1.7rem;
			// }
		}
	}
	tbody {
		td {
			text-align: center;
			padding: .75rem .75rem .25rem .75rem;
			button {
				margin-right: 5px;
				margin-bottom: 5px;
			}
		}
	}
}

.table-responsive {
	margin-bottom: 20px;
	@media (max-width: 767px){
	position: relative;
	height: 30rem;
	overflow: auto;
	display: block;
	}
}

.no-table-data {
	font-family: $font-family-bold;
	color: $black;
	font-size: 18px;
	background-color: $white;
	border-radius: 2px;
	text-align: center;
	padding: 10px;
	border-bottom-left-radius: $table-radius;
	border-bottom-right-radius: $table-radius;
}

.table-container {
	// @include shadow();
	border-bottom-left-radius: $table-radius;
	border-bottom-right-radius: $table-radius;
}

	