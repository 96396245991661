// Chat

#chat-admin {
	.triangle-left {
      width: 0;
      height: 0;
      border-top: 15px solid $gray;
      border-right: 15px solid transparent;
      position: absolute;
      bottom: -15px;
      left: 0;
	}
	.no-chat {
		font-family: $font-family;
		font-size: 20px;
		text-align: center;
		margin-top: 10px;
	}
	.triangle-right {
      width: 0;
      height: 0;
      border-top: 15px solid $blue;
      border-left: 15px solid transparent;
      position: absolute;
      bottom: -15px;
      right: 0;
	}
	.container-chat {
		height: 90vh;
		// background-color: $white;
		width: 100%;
        @include shadow();
        border-radius: $general-radius;
        // border: 1px solid $black;
	}
	.list-chats {
		height: 90vh;
        overflow-y: auto;
        margin-right: 8px;
        background-color: $gray-green;;
		// border-right: 1px solid $gray;
		@include scroll();
		@media (max-width: 767px) {
			height: 26vh;
        }
        border-bottom-left-radius: $general-radius;
        border-top-left-radius: $general-radius;
	}
	.no-padding-left {
		@media (min-width: 768px) {
			padding-left: 0px !important;
		}		
	}
	.no-padding-right {
		padding-right: 0px !important;
	}
	.item-chat {
		padding: 1rem;
		border-bottom: 1px solid $black;
		position: relative;
		cursor: pointer;
		&.active {
			background-color: $green;
		}
		.badge {
			position: absolute;
			right: 5px;
			top: 5px;
			background-color: $yellow;
			color: $white;
			width: 20px;
			height: 20px;
			border-radius: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		i.fa {
			position: absolute;
			right: 5px;
			bottom: 5px;
			color: $green;
		}
		h2 {
			color: $black;
			font-family: $font-family;
			font-size: 15px;
			margin: 0px !important;
		}
		p:not(.date) {
			font-size: 13px;
			margin: 0px !important;
			margin-top: 5px;
			margin-bottom: 5px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 80%;
		}
		p.date {
            font-family: $font-family-light;
			font-size: 16px;
			margin: 0 !important;
			color: $black;
		}
	}
	.top-messages {
		background-color: $green2;
		display: flex;
		color: $white;
		align-items: center;
        // height: 40px;
        padding-top: .8rem;
        padding-bottom: .8rem;
		h2 {
			font-family: $font-family;
			color: $white;
			font-size: 16px;
			margin-left: 10px;
			margin-top: 5px;
			span {
				font-family: $font-family;
			}
		}
		button {
			position: absolute;
			right: 10px;
			background-color: $white !important;
			height: 30px;
			width: 30px;
			i.fa {
				color: $blue !important;
			}
		}
	}
	.container-messages {
		height: 90vh;
        position: relative;
        background-color: $gray-green;
        border-bottom-right-radius: $general-radius;
        border-top-right-radius: $general-radius;
        border-right: 1px solid $black;

		@media (max-width: 767px) {
			margin-bottom: 20px;
			@include shadow();
			background-color: $gray-green;
			border-top: 1px solid $gray;
			height: 70vh;
		}
		.container-input {
			position: absolute;
			bottom: 0px;
			display: flex;
			flex-direction: row;
			background-color: $green3;
			min-width: 100%;
			justify-content: center;
			align-items: center;
            // padding: 0px !important;
            padding-top: .5rem;
            padding-bottom: .5rem;
            border-bottom-right-radius: $general-radius;
			.form-group {
				width: 83%;
				@media (max-width: 1225px) and (min-width: 921px) {
					width: 80%;
				}
				@media (max-width: 660px) {
					width: 75%;
				}
				@media (max-width: 920px) and (min-width: 768px) {
					width: 75%;
				}
			}
			input {
				position: relative;
				top: 8px;
                border-radius: 30px !important;
                background-color: $white !important;
                padding-top: 22px !important;
                padding-bottom: 22px !important;
			}
			button {
				width: 40px;
				height: 40px;
				min-width: auto !important;
				border-radius: 80px;
				margin-left: 24px;
				margin-right: 20px;
                background-color: $input !important;
                padding: 5px !important;
				i.fa {
					color: $blue !important;
					font-size: 30px;
					margin-top: -3px;
					margin-left: 2px;
				}
			}
		}
		.container-scroll {
			@include scroll();
			display: flex;
			flex-direction: column;
			padding: 10px;
			overflow-y: auto;
			height: calc(90vh - 75.59px - 77px);
			// background-color: $white;
			@media (max-width: 767px) {
				
				height: 50vh;
			}
		}
		.message {
			padding: 10px;
			width: 50%;
			margin-top: 10px;
			margin-bottom: 10px;
			border-radius: $general-radius;
			position: relative;
			h2 {
                font-size: 14px;
                font-family: $font-family-light;
                margin-bottom: 1.5rem;
			}
			p {
                color: $black;
                font-family: $font-family-light;
				font-size: 11px;
				margin-bottom: 0px !important;
			}
			&.right {
				background-color: $white;
				align-self: flex-end;
				text-align: right;
				margin-right: 0px;
			}
			&.left {
				background-color: $white;
				align-self: flex-start;
			}
		}
	}
}