// Base

* {
	font-family: $font-family;
}

body {
	background-color: $white;
}

.container-menu {
	display: flex;
	flex-direction: row;
}

.navbar-toggler {
	border: 0px !important;
	outline: 0px !important;
}

.navbar {	
	@media (min-width: 768px) {
		display: none;
			
	}
	@media (max-width: 767px) {
		display: none;
			
	}
	.navbar-collapse {
		a {
			color: $white !important;
		}
	}
}
.modal.high-modal {
	.modal-dialog {
		max-width: 80% !important;
		@media (max-width: 600px) {
			max-width: 100% !important;
		}
	}
}
.nav-link {
	.badge {
		background-color: $blue;
		margin-left: 10px;
		border-radius: 30px;
	}
}

.menu {
	@media (max-width: 320px) {
		display: none;
	}
	min-height: 100vh;
	background-color: $green3;
	width: $width-menu;
	position: relative;
	.container-user {
		display: flex;
		align-items: center;
		margin-top: 40px;
		margin-bottom: 20px;
		margin-left: 15px;
		width: 80%;
		img {
			width: 50px;
			height: 50px;
			border-radius: 100px;
			margin-right: 20px;
			object-fit: cover;
		}
		h2 {
			color: $white;
			font-family: $font-family-bold;
			font-size: 16px;
			margin-top: 10px;
		}
	}
	.btn-logout {
		color: $white !important;
		position: absolute;
		right: 10px;
		top: 10px;
		font-size: 20px;
		box-shadow: none !important;
		border: 0px !important;
		outline: 0px !important;
	}
	ul {
		padding: 0px !important;
		list-style: none;
		margin-top: 3rem;
		li {
			padding-left: 13px !important;
			&.active {
				background-color: $green2;
				border-left: 13px solid $golden !important;
				padding-left: 0 !important;
			}			
			a {
				position: relative;
				display: flex;
				align-items: center;
				color: $white !important;
				text-decoration: none !important;
				padding: 10px !important;
				.badge {
					background-color: $yellow;
					margin-left: 10px;
					border-radius: 30px;
				}
				i {
					position: absolute;
					right: 10px;
				}

				.nav-link-icon {
					margin-left: .5rem;
				}
			}
		}
	}
}

.container-router {
	padding: 20px;
	width: 100%;
	height: calc(100vh - 100px);
	@media (max-width: 767px) {
		padding-top: 5%;
	}

	&.closed {
		padding-left: 130px;
		@media (max-width: 767px) {
			padding-left: 25%;
		}
	}
}

.ver-todo {
	color: $blue;
	display: inline-block;
	cursor: pointer;
	font-family: $font-family-bold;
	margin-top: 0px !important;
}

.btn-profile {
	margin-right: 40px;
}

.fa.danger {
	color: $danger;
}

.fa.success {
	color: $green;
}

.react-viewer {
	position: relative;
	z-index: 9999;
}

.input-file-paperclip {
	display: none;
}

.container-paper-clip {
	button {
		border-radius: 23px !important;
		min-width: 150px;
	}
	.fa {
		margin-right: 5px;
	}
}

.swal-custom-popup {
	border-radius: 1rem;
	.swal-custom-color-icons {
		.swal2-icon {
			border-color: $black;
			color: $black;
			.swal2-x-mark-line-left, .swal2-x-mark-line-right, .swal2-success-line-tip, .swal2-success-line-long {
				background-color: $black;
			}
			.swal2-success-ring {
				border: .25em solid $black;
			}
		}
	}
	.swal-custom-actions {
		.swal2-styled {
			border-radius: 1.5rem;
			padding: 0.5em 2.25em;
		}
		.swal2-styled:focus {
			box-shadow: none;
		}
		.swal-custom-confirm-button {
			background-color: $yellow;
		}
		.swal-custom-cancel-button {
			background-color: $gray-light !important	;
		}
	}
}

.bg-primary {
	background-color: $golden !important;
}

.bg-gray-light {
	background-color: $gray-light !important;
}
.bg-crem {
	background-color: $cream !important;
}

.msg-evaluation-photos {
	font-size: 12px;
}

.stripe-container {
	margin: 0 auto;
	margin-top: 20px;
	max-width: 350px;
	text-align: center;
	img {
		max-width: 150px;
		margin: 0 auto;
		margin-bottom: 35px;
	}
	button {
		margin-top: 35px;
		outline: 0px !important;
		border: 0px !important;
		box-shadow: none !important;
		background-color: $green1 !important;
		color: $white !important;
		padding-bottom: 5px;
		padding-top: 5px;
	}
	.container-loading {
		margin-top: 20px;
		margin-bottom: 20px;
	}
}