// Input

input.form-control {
	color: $black;
	border-radius: $general-radius !important;
	background-color: $cream !important;
	outline: 0px !important;
	box-shadow: none !important;
	border: 0px !important;
	font-size: 14px;
	padding-top: 20px !important;
	padding-bottom: 20px !important;
	// &.input-white {
	// 	background-color: $white !important;
	// }
	&.input-gray{
		background-color: $cream !important;
		color: $black;
	}
	
}
input.form-control::placeholder{
	color: $black;
}