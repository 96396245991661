// Chat

#chat-client {
	.triangle-left {
      width: 0;
      height: 0;
      border-top: 15px solid $gray;
      border-right: 15px solid transparent;
      position: absolute;
      bottom: -15px;
      left: 0;
	}
	.no-chat {
		font-family: $font-family;
		font-size: 20px;
		text-align: center;
		margin-top: 10px;
	}
	.triangle-right {
      width: 0;
      height: 0;
      border-top: 15px solid $blue;
      border-left: 15px solid transparent;
      position: absolute;
      bottom: -15px;
      right: 0;
	}
	.container-chat {
		height: 90vh;
		//background-color: $white;
		width: 100%;
		-webkit-border-radius: 15px;
		-moz-border-radius: 15px;
		border-radius: 15px;
		@include shadow();
	}
	.list-chats {
		height: 90vh;
		overflow-y: scroll;
		border-right: 1px solid $gray-blue;
		@include scroll();
		@media (max-width: 767px) {
			height: 45vh;
		}
	}
	.no-padding-left {
		@media (min-width: 768px) {
			padding-left: 0px !important;
		}		
	}
	.no-padding-right {
		padding-right: 0px !important;
	}
	.item-chat {
		padding: 10px;
		border-bottom: 1px solid $gray;
		position: relative;
		cursor: pointer;
		&.active {
			background-color: $blue;
		}
		.badge {
			position: absolute;
			right: 5px;
			top: 5px;
			background-color: $blue;
			color: $white;
			width: 20px;
			height: 20px;
			border-radius: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		i.fa {
			position: absolute;
			right: 5px;
			bottom: 5px;
			color: $green;
		}
		h2 {
			color: $blue;
			font-family: $font-family-bold;
			font-size: 12px;
			margin: 0px !important;
		}
		p:not(.date) {
			font-size: 13px;
			margin: 0px !important;
			margin-top: 5px;
			margin-bottom: 5px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 80%;
		}
		p.date {
			font-size: 12px;
			margin: 0 !important;
			color: #616161;
		}
	}
	.top-messages {
		background-color: $green2;
		display: flex;
		color: $white;
		align-items: center;
		height: 70px;
		-webkit-border-top-left-radius: 10px;
		-webkit-border-top-right-radius: 10px;
		-moz-border-radius-topleft: 10px;
		-moz-border-radius-topright: 10px;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		h2 {
			font-family: $font-family;
			color: $white;
			font-size: 16px;
			margin-left: 10px;
			margin-top: 5px;
			span {
				font-family: $font-family;
			}
		}
		button {
			position: absolute;
			right: 10px;
			background-color: $white !important;
			height: 30px;
			width: 30px;
			i.fa {
				color: $white !important;
			}
		}
		.top-messages-admin-avatar {
			width: 50px;
			height: 50px;
			border-radius: 50%;
			border: 2px solid $golden;
			background-image: url('../../assets/img/avatarDefault.png');
			background-size: cover;
			margin-left: 1.5rem;
			margin-right: 0.5rem;
			background-position: center;
		}
	}
	.container-messages {
		height: 90vh;
		position: relative;
		@media (max-width: 767px) {
			margin-bottom: 20px;
			@include shadow();
			background-color: $gray-green;
			border-top: 1px solid $gray;
		}
		.container-input {
			position: absolute;
			bottom: 0px;
			display: flex;
			flex-direction: row;
			background-color: $green3;
			min-width: 100%;
			justify-content: center;
			align-items: center;
			padding: 0px !important;
			height: 70px;
			-webkit-border-bottom-right-radius: 10px;
			-webkit-border-bottom-left-radius: 10px;
			-moz-border-radius-bottomright: 10px;
			-moz-border-radius-bottomleft: 10px;
			border-bottom-right-radius: 10px;
			border-bottom-left-radius: 10px;
			.form-group {
				width: 92%;
				margin-left: -2%;		
				@media (max-width: 1441px) and (min-width: 921px) {
					width: 90%;
				}	
				@media (max-width: 1225px) and (min-width: 921px) {
					width: 87%;
				}
				@media (max-width: 660px) {
					width: 82%;
				}
				@media (max-width: 920px) and (min-width: 768px) {
					width: 82%;
				}
				.form-control {
					background-color: $white !important;
					height: calc(2.25em + .75rem + 2px);
				}
			}
			input {
				position: relative;
				top: 8px;
				border-radius: 30px !important;
			}
			button {
				width: 40px;
				height: 40px;
				min-width: auto !important;
				border-radius: 80px;
				margin-left: 10px;
				background-color: $gray !important;
				img.chat-send-message-icon {
					color: $black !important;
					width: 30px; 
					height: 30px;
					@media (max-width: 1441px) and (min-width: 921px) {
						width: 27px; 
						height: 27px;
					}	
					@media (max-width: 1225px) and (min-width: 921px) {
						width: 24px; 
						height: 24px;
					}
					@media (max-width: 660px) {
						width: 21px; 
						height: 21px;
					}
					@media (max-width: 920px) and (min-width: 768px) {
						width: 21px; 
						height: 21px;
					}
				}
			}
		}
		.container-scroll {
			@include scroll();
			display: flex;
			flex-direction: column;
			padding: 10px;
			overflow-y: scroll;
			height: calc(90vh - 58px - 40px);
			background-color: $white;
		}
		.message {
			padding: 10px;
			width: 50%;
			margin-top: 10px;
			margin-bottom: 10px;
			border-radius: 3px;
			position: relative;
			h2 {
				font-size: 12px;
			}
			p {
				color: #616161;
				font-size: 11px;
				margin-bottom: 0px !important;
			}
			&.right {
				background-color: $white;
				align-self: flex-end;
				text-align: right;
				border-bottom-right-radius: 0px !important;
				margin-right: 0px;
			}
			&.left {
				border-bottom-left-radius: 0px !important;
				background-color: $white;
				align-self: flex-start;
			}
		}
	}
}