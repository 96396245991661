// Profile

#profile {
	h2 {
		font-size: 20px;
		font-family: $font-family-bold;
		text-align: left;
		margin-bottom: 20px;
		color: $black;
		text-align: center;
	}
}