// Mobile

.container-mobile {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	min-height: 100vh;
	padding: 20px;

	h2, h4 {
		color: $mobile;
		text-align: center;
	}

	h2 {
		font-weight: bold;
		margin-bottom: 40px;
		margin-top: 20px;
		font-size: 25px;
	}

	h4 {
		font-size: 16px;
		margin-bottom: 50px;
	}

	.row {
		img {
			width: 100%;
			margin: 0 auto;
		}
	}
}