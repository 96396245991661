// Pagination

.pagination {
	display: flex;
	align-items: center;
	.page-link, .page-item {
		text-decoration: none !important;
		outline: 0px !important;
		box-shadow: none !important;
		color: $white!important;
		background-color: $blue2!important;
		border-color: $gray-blue2 !important;
		font-family: $font-family-light;

		&.disbled {
			background-color: $white!important;
			border-color: $gray-blue !important;
		}
	}
	.page-item.active {
		.page-link {
			font-family: $font-family;
			color: $white !important;
			background-color: $blue3 !important;
			border-color: $gray-blue !important;
		}
	}
}