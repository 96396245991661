@font-face {
    font-family: $font-family;
    src: url($fonts-path+$font-family+".ttf");
}

@font-face {
    font-family: $font-family-bold;
    src: url($fonts-path+$font-family-bold+".ttf");
}

@font-face {
    font-family: $font-family-black;
    src: url($fonts-path+$font-family-black+".ttf");
}

@font-face {
    font-family: $font-family-medium;
    src: url($fonts-path+$font-family-medium+".ttf");
}

@font-face {
    font-family: $font-family-light;
    src: url($fonts-path+$font-family-light+".ttf");
}

@font-face {
    font-family: $font-family-thin;
    src: url($fonts-path+$font-family-thin+".ttf");
}

.font-regular {
    font-family: $font-family !important;
}
.font-bold {
    font-family: $font-family-bold !important;
}
.font-black {
    font-family: $font-family-black !important;
}
.font-light {
    font-family: $font-family-light !important;
}
.font-thin {
    font-family: $font-family-thin !important;
}
.font-medium {
    font-family: $font-family-medium !important;
}
.text-yellow {
    color: $yellow;
}
.text-gray {
    color: $gray;
}
.text-gray-light {
    color: $gray-light;
}
.text-gray-dark {
    color: $gray-dark;
}
