// Image
.image-component {
    background-color: $black;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0;
    padding: 1rem;
    .form-check-label {
        color: $white !important;
        margin-top: 1rem;
        text-align: center;
    }

    &--rounded {
        border-radius: $general-radius;
    }

    &--gray-blue {
        background-color: $gray-blue;
    }

    &--unlessmargin {
        margin: 0;
    }
}

.border-golden {
    border: 3px $golden solid;
}