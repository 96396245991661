// List

.strong-item-list {
	font-family: $font-family-bold;
}

.list-group-item {
	img {
		margin-right: 15px;
	}
}