// Appointments

#appointments {
  .ellipsis {
    @include ellipsis();
    max-width: 150px;
  }
}

.view-appointment {
  .btn-finish {
    width: 100%;
    i.fa {
      margin-right: 10px;
    }
  }

  .row-buttons {
    margin-bottom: 20px;
  }

  .btn-new {
    margin-bottom: 20px;
    i.fa {
      margin-right: 10px;
    }
  }

  .row-buttons-recipes {
    margin-top: 20px;
  }
}

#client-appointments-view {
  .message-no-appointments {
    text-align: center;
    margin-bottom: 20px;
    font-weight: $font-family-bold;
    font-size: 18px;
    color: #f44336;
  }
  h3 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
    font-family: $font-family-light;
  }
  .container-buttons {
    margin-top: 20px;
    button:not(.btn-epayco):not(.react-datepicker__navigation) {
      margin-right: 10px;
      margin-left: 10px;
      border-radius: 23px;
      margin-bottom: 20px;
      width: 180px;
      i.fa {
        margin-right: 5px !important;
        margin-left: 5px !important;
        color: $white;
      }
    }
    .bold {
      font-family: $font-family-bold;
    }
    .amount {
      text-align: center;
      font-size: 18px;
      font-family: $font-family-light;
    }
  }
  .owl-carousel {
    margin-top: 20px;
  }
  .row-pay {
    margin-bottom: 20px;
  }
  .item-photo {
    width: 90%;
    height: 200px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    cursor: pointer;
  }
  .paypal-button {
    height: 45px !important;
  }
  .h2-title-carousel {
    font-family: "$font-family-light";
    margin-bottom: 10px;
    font-size: 20px;
    margin-top: 15px;
    color: $yellow;
    &.h2-margin-bottom {
      margin-bottom: 20px;
    }
  }
  label {
    color: $black;
  }
  div.container-tabs {
    background-color: transparent;
    .tab-item {
      padding-top: 5px;
      padding-bottom: 5px;
      width: 27vw;
      background-color: $gray-green;
    }
    .tab-selected {
      background-color: $green;
    }
  }
}

#modal-create-appointment-client {
  @media (min-width: 768px){
    max-width: 60% !important;
    .modal-body {
      padding: 1.75rem;
    }
  }
}
