// Mixins

@mixin shadow() {
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

@mixin scroll() {
	/* width */
	&::-webkit-scrollbar {
	  width: 5px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
	  background: #f1f1f1; 
	}
	 
	/* Handle */
	&::-webkit-scrollbar-thumb {
	  background: #888; 
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
	  background: #555; 
	}
}

@mixin ellipsis() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}