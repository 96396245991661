// Create Edit Moderators

.modal-create-edit-moderators {
	.container-button {
		max-width: 350px;
		margin: 0 auto;	
		margin-top: 20px;
	}

	h3 {
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: 20px;
		font-family: $font-family-light;
		color: $blue;
	}
}