// Progress Bar

.progress {
	height: auto;
	margin-bottom: 20px;
	.progress-bar {
		padding-top: 5px;
		padding-bottom: 5px;
		cursor: pointer;
	}
}