// Evaluations

#evaluations {
	ul {
		margin: 0px;
	}
	.item-procedure {
		font-size: 12px;
		margin: 0px;
		p {
			margin: 0px !important;
		}
	}

	.ellipsis {
		@include ellipsis();
		max-width: 250px;
	}
}

.modal-view-evaluation {
	.item-photo {
		width: 90%;
		height: 200px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		margin: 0 auto;
		cursor: pointer;
	}

	ul {
		margin: 0px;
	}

	.edit-budget {
		color: $blue;
		margin: 0px;
		cursor: pointer;
	}

	.h2-title-carousel {
		font-family: $font-family-bold;
		margin-bottom: 10px;
		font-size: 20px;
		margin-top: 15px;
		color: $black;
		&.h2-margin-bottom {
			margin-bottom: 20px;
		}
	}
}

.container-messages {
	.row-buttons-container {
		display: flex;
		justify-content: flex-end;
	}
	button {
		width: 160px;
	}
	.row-buttons-messages {
		max-width: 400px;
		justify-content: flex-end;
		i.fa {
			margin-right: 10px;
		}
	}
	.messages-box {
		background-color: $cream;
		height: 50vh;
		border-radius: 3px;
		margin-bottom: 20px;
		overflow: auto;
		padding: 20px;
		display: flex;
		flex-direction: column;
	}
	.item-message {
		padding: 10px;
		border-radius: 3px;
		margin-bottom: 20px;
		min-width: 30%;
		p:not(.date):not(.name) {
			margin-top: 5px;
			margin-bottom: 5px;
			a {
				color: $white !important;
				text-decoration: underline;
			}
		}
		p {
			font-family: $font-family-light;
			color: $white;
			font-size: 12px;
			margin: 0px;
		}
		p.name {
			font-family: $font-family-bold;
		}
		&.right {
			background-color: $blue;
			align-self: flex-end;
			text-align: right;
		}
		&.left {
			background-color: $gray-blue2;
			align-self: flex-start;
			text-align: left;
		}
		.date {
			font-size: 10px;
			font-style: italic;
			margin-top: 5px;
		}
	}
	.photo-message {
		width: 100px;
		height: 100px;
		border-radius: 3px;
		object-fit: cover;
		cursor: pointer;
	}
}

.no-payment {
	text-align: center;
	font-family: $font-family-light;
	font-size: 20px;
}

.modal-evaluations {
	.modal-body {
		padding: 0 !important;
	}
}

#client-evaluations-view {
	
	.controls {
		margin-top: 1rem;
		margin-bottom: 1rem;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		.prev, .next {
			margin-left: 1rem;
			margin-right: 1rem;
			background-color: $white;
			cursor: pointer;
			img {
				width: 30px;
				width: 30px;
			}
		}
		.dots {
			display: flex;
			justify-content: center;
			.dot {
				margin-left: .3rem;
				margin-right: .3rem;
				width: 7px;
				height: 7px;
				border-radius: 50%;
				background-color: $gray-dark;
	
				&--selected {
					background-color: $gray-blue2;
				}
			}
		}
	}
	.custom-progress {
		display: flex;
		&-item {
			width: 33.3%;

			&--inactive {
				color: $gray;
			}
		}
	}
	h3 {
		margin-top: 15px;
		// margin-bottom: 15px;
		font-size: 20px;
		text-align: center;
		font-family: $font-family-light;
	}
	.container-buttons {
		// margin-top: 20px;
		button:not(.btn-epayco):not(.react-datepicker__navigation) {
			margin-right: 10px;
			margin-left: 10px;
			border-radius: 23px;
			margin-bottom: 20px;
			width: 170px;
			i.fa {
				margin-right: 5px !important;
				margin-left: 5px !important;
				color: $white;
			}
		}
		.bold {
			font-family: $font-family-bold;
		}
		.amount {
			text-align: center;
			font-size: 18px;
			font-family: $font-family-light;
		}

		
	}
	.row-pay {
		margin-bottom: 20px;
	}
	.treatment-info {
		max-height: 70vh;
		overflow-y: scroll;

		&-header {
			position: relative;
			.prev-btn, .next-btn {
				position: absolute;
				top: 50%;
				margin-left: 1rem;
				margin-right: 1rem;
				cursor: pointer;
				z-index: 999999;
				img {
					width: 30px;
					width: 30px;
				}
			}
	
			.prev-btn {
				left: 10%;
			}
	
			.next-btn {
				right: 10%;
			}
		}

		.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
			background-color: $yellow;
		}
		padding-left: 4rem;
		padding-right: 2rem;
		padding-bottom:.5rem;
		margin-right: 2rem;
	}
	.treatments-title {
		color: $black;
		font-size: 1.4rem;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 2.3rem;
		margin-bottom: 2rem;
		.line {
			background-color: $black;
			height: 2px;
			flex: 1;

		}
		.text {
			margin-left: 1rem;
			margin-right: 1rem;
		}
	}
	.treatment-footer {
		border-top: 3px solid $gray;
		padding-top: 1.5rem;
	}
	.item-photo {
		// width: 60%;
		height: 200px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		margin: 0 auto;
		cursor: pointer;
		margin-right: 20px;

		&.w-60 {
			width: 60% !important;
			margin: 0 auto;
		}
	}
	.paypal-button {
		height: 45px !important;
	}
	.h2-title-carousel {
		font-family: $font-family-light;
		font-weight: bold;
		margin-bottom: 20px;
		font-size: 20px;
		margin-top: 10px;
		color: $black;
		&.h2-margin-bottom {
			margin-bottom: 20px;
		}
	}
	.patient-info {
		input.form-control, select.form-control {
			border-radius: 0 !important;
			font-size: 1rem;
			@media (max-width: 767px){
				width: 4rem;
			}
			&::placeholder {
				font-size: 1rem;
				font-family: $font-family-medium;
			}
		}

		.container-datepicker {
			font-size: 1rem;
			border-radius: 0 !important;
		}

		&-inputs {
			width: 35%;
			padding-right: 1rem;
		}

		&-area {
			width: 65%;
			.form-group {
				height: calc(100% - 1rem) !important;
			}
		}
		textarea.form-control {
			height: 100% !important;
			padding-top: 5px !important;
			&::placeholder {
				font-size: 1rem;
				font-family: $font-family-medium;
			}
		}
	}

	.uploads {
		.owl-stage {
			display: flex;
			.item, .image-component {
				height: 100% !important;
			}
		}
		.btn-img{
			@media(max-width:767px){
				display: none;
			}
		}
	}

	.radius {
		border-radius: 23px;
		padding-top: 4px;
		padding-bottom: 4px;
	}

	.owl-carousel .owl-stage:after {
		content: '';
	}

	.photos-patient {
		position: relative;
	}

	.prev-btn, .next-btn {
		position: absolute;
		top: 45%;
		margin: 0;
		margin-left: 1rem;
		margin-right: 1rem;
		cursor: pointer;
		z-index: 999999;
		img {
			width: 30px;
			width: 30px;
		}
	}

	.prev-btn {
		left: 30px;
	}

	.next-btn {
		right: 30px;
	}
}

.container-select-weight, .container-select-height {
	display: flex;
	select {
		-o-appearance: none;
	   -ms-appearance: none;
	   -webkit-appearance: none;
	   -moz-appearance: none;
	   appearance: none;
	}
	// select:not([disabled]) {
	// 	background-image:
	// 	    linear-gradient(45deg, transparent 50%, gray 50%),
	// 	    linear-gradient(135deg, gray 50%, transparent 50%),
	// 	    linear-gradient(to right, transparent, transparent);
	// 	background-position:
	// 	    calc(100% - 20px) calc(1em + 2px),
	// 	    calc(100% - 15px) calc(1em + 2px),
	// 	    calc(100% - 2.5em) 0.5em;
	// 	background-size:
	// 	    5px 5px,
	// 	    5px 5px,
	// 	    1px 1.5em;
	// 	background-repeat: no-repeat;
	// }
}