// ePayco

.epayco {
	.btn-epayco {
		background-color: $gray-blue !important;
		border-radius: 23px;
		height: 45px;
		width: 100%;
		max-width: 350px;
		img {
			height: 80%;
			margin-top: 1%;
		}
	}
	form {
		background-color: $gray-blue !important;
		border-radius: 8px;
		padding: 20px;
		button[type="submit"] {
			background-color: $epayco !important;
			border-radius: 23px;
			height: 45px;
			width: 100% !important;
			max-width: 350px;
			margin-bottom: 0px !important;
			font-family: $font-family-bold;
			padding-top: 10px;
			img {
				height: 80%;
			}
		}
	}
}